import apiRoutes from './apiRoutes';
import apiService, { baseURL } from './apiService';

export const getUserProfiles = async (
  id: string | null = null,
  company_user_id?: number
) => {
  let url = `${baseURL}${apiRoutes.getProfiles}`;
  if (id) {
    url = `${baseURL}${apiRoutes.getProfiles}?profile_id=${id}`;
  }
  if (company_user_id) {
    url = `${baseURL}${apiRoutes.getProfiles}?company_user_id=${company_user_id}`;
  }

  try {
    const response = await apiService({
      method: 'GET',
      url: url,
      data: null,
      params: null,
    });
    const dataToType = response.data;
    return dataToType;
  } catch (error) {
    console.error('Error fetching jobs:', error);
    throw error;
  }
};

export const addSearchLinks = async (
  profileLinkedJobPortalId: number,
  links: { search_links: string[] }
) => {
  try {
    const response = await apiService({
      method: 'POST',
      url: `${baseURL}${apiRoutes.addSearchLinks}/${profileLinkedJobPortalId}`,
      data: links,
      params: null,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching jobs:', error);
    throw error;
  }
};

export const addProfile = async (profile: any) => {
  try {
    const response = await apiService({
      method: 'POST',
      url: `${baseURL}${apiRoutes.addProfile}`,
      data: profile,
      params: null,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching jobs:', error);
    throw error;
  }
};

export const addApplicantDetails = async (profile: any) => {
  try {
    const response = await apiService({
      method: 'POST',
      url: `${baseURL}${apiRoutes.addApplicant}`,
      data: profile,
      params: null,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching jobs:', error);
    throw error;
  }
};

export const scheduleJobApplying = async (data: any) => {
  try {
    const response = await apiService({
      method: 'POST',
      url: `${baseURL}${apiRoutes.scheduleJobApplying}`,
      data: data,
      params: null,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching jobs:', error);
    throw error;
  }
};

export const increaseProfileLimit = async (profileCount: number) => {
  try {
    const response = await apiService({
      method: 'GET',
      url: `${baseURL}${apiRoutes.increaseProfileLimit}/${profileCount}`,
      data: null,
      params: null,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching jobs:', error);
    throw error;
  }
};

export const deleteProfile = async (profileId: number) => {
  try {
    const response = await apiService({
      method: 'DELETE',
      url: `${baseURL}${apiRoutes.deleteProfile}/${profileId}`,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching jobs:', error);
    throw error;
  }
};

export const getDiscoveredJobs = async (data: any) => {
  try {
    const response = await apiService({
      method: 'GET',
      url: `${baseURL}${apiRoutes.getDiscoveredJobs}`,
      data: null,
      params: { ...data },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching jobs:', error);
    throw error;
  }
};

export const getDiscoveredJobPortals = async (profileId?: string) => {
  const url = profileId
    ? `${baseURL}${apiRoutes.getDiscoverPortals}?profile_id=${profileId}`
    : `${baseURL}${apiRoutes.getDiscoverPortals}`;
  try {
    const response = await apiService({
      method: 'GET',
      url,
      data: null,
      params: null,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching jobs:', error);
    throw error;
  }
};

export const getDiscoveryLocations = async () => {
  try {
    const response = await apiService({
      method: 'GET',
      url: `${baseURL}${apiRoutes.getLocations}`,
      data: null,
      params: null,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching jobs:', error);
    throw error;
  }
};

export const uploadResume = async (formData: any) => {
  try {
    const response = await apiService({
      method: 'POST',
      url: `${baseURL}${apiRoutes.uploadResume}`,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching jobs:', error);
    throw error;
  }
};

export const stepForm = async (formData: any) => {
  try {
    const response = await apiService({
      method: 'POST',
      url: `${baseURL}${apiRoutes.stepForm}?step=${formData.step}&is_new_profile=${formData.is_new_profile}`,
      data: formData,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching jobs:', error);
    throw error;
  }
};

export const selectDiscoveryPortals = async (formData: any) => {
  try {
    const response = await apiService({
      method: 'POST',
      url: `${baseURL}${apiRoutes.selectDiscoveryPortals}?profile_id=${formData.profile_id}`,
      data: formData,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching jobs:', error);
    throw error;
  }
};
export const connectToJobPortal = async (formData: any) => {
  try {
    const response = await apiService({
      method: 'POST',
      url: `${baseURL}${apiRoutes.connectToJobPortal}`,
      data: formData,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching jobs:', error);
    throw error;
  }
};

export const deleteFetchJobsNamespace = async (profileId: number) => {
  try {
    const response = await apiService({
      method: 'GET',
      url: `${baseURL}${apiRoutes.deleteFetchJobsNamespace}?profile_id=${profileId}`,
      data: null,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching jobs:', error);
    throw error;
  }
};

export const startDiscoveringJobs = async (profileId: number) => {
  try {
    const response = await apiService({
      method: 'GET',
      url: `${baseURL}${apiRoutes.startDiscoveringJobs}?profile_id=${profileId}`,
      data: null,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching jobs:', error);
    throw error;
  }
};

export const googleMapAutoComplete = async (input: string) => {
  try {
    const response = await apiService({
      method: 'GET',
      url: `${apiRoutes.googleMapAutoComplete}?input=${input}&key=${process.env.NEXT_PUBLIC_GOOGLE_MAP_API_KEY}`,
      data: null,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching jobs:', error);
    throw error;
  }
};

export const updateJobSeenStatus = async (
  localProfileId: number,
  job_id: number
) => {
  try {
    const response = await apiService({
      method: 'GET',
      url: `${baseURL}${apiRoutes.updateJobSeenStatus}?profile_id=${localProfileId}&job_discovery_id=${job_id}`,
    });
    return response.data;
  } catch (error) {
    console.error('Error updating job as seen:', error);
    throw error;
  }
};

export const disconnectPortal = async (profilePortalId: number) => {
  try {
    const response = await apiService({
      method: 'PUT',
      url: `${baseURL}${apiRoutes.disconnectPortal}/${profilePortalId}`,
    });
    return response.data;
  } catch (error) {
    console.error('Error updating job as seen:', error);
    throw error;
  }
};

export const pauseAutomation = async (
  profileId: string,
  isAutomationPaused: boolean
) => {
  try {
    const response = await apiService({
      method: 'GET',
      url: `${baseURL}${apiRoutes.pauseAutomation}/${profileId}/${isAutomationPaused}`,
    });
    return response.data;
  } catch (error) {
    console.error('Error updating job as seen:', error);
    throw error;
  }
};

export const getSchedulers = async (profileId: number, jobPortalId: number) => {
  try {
    const response = await apiService({
      method: 'GET',
      url: `${baseURL}${apiRoutes.getScheduler}/${profileId}/${jobPortalId}`,
    });
    return response.data;
  } catch (error) {
    console.error('Error updating job as seen:', error);
    throw error;
  }
};

export const getPortalsInfo = async (profileId: number) => {
  try {
    const response = await apiService({
      method: 'GET',
      url: `${baseURL}${apiRoutes.getPortalsInfo}/${profileId}`,
    });
    return response.data;
  } catch (error) {
    console.error('Error updating job as seen:', error);
    throw error;
  }
};

export const getOverviewData = async () => {
  try {
    const response = await apiService({
      method: 'GET',
      url: `${baseURL}${apiRoutes.getOverviewData}`,
    });
    return response.data;
  } catch (error) {
    console.error('Error updating job as seen:', error);
    throw error;
  }
};

export const getApplyOverviewData = async (period: string) => {
  try {
    const response = await apiService({
      method: 'GET',
      url: `${baseURL}${apiRoutes.getApplyOverviewData}/${period}`,
    });
    return response.data;
  } catch (error) {
    console.error('Error updating job as seen:', error);
    throw error;
  }
};

export const getStageOverviewData = async (period: string) => {
  try {
    const response = await apiService({
      method: 'GET',
      url: `${baseURL}${apiRoutes.getStageOverviewData}/${period}`,
    });
    return response.data;
  } catch (error) {
    console.error('Error updating job as seen:', error);
    throw error;
  }
};

export const getUserAnalytics = async (period: string, profile: string) => {
  try {
    const response = await apiService({
      method: 'GET',
      url: `${baseURL}${apiRoutes.getUserAnalytics}/${period}/${profile}`,
    });
    return response.data;
  } catch (error) {
    console.error('Error updating job as seen:', error);
    throw error;
  }
};

export const getJobTitleSuggestions = async (user_string: string) => {
  try {
    const response = await apiService({
      method: 'GET',
      url: `${baseURL}${apiRoutes.getJobTitleSuggestions}?user_string=${user_string}`,
    });
    return response.data;
  } catch (error) {
    console.error('Error updating job as seen:', error);
    throw error;
  }
};

export const getPublicIP = async () => {
  try {
    const response = await apiService({
      method: 'GET',
      url: `https://api.ipify.org?format=json`,
    });
    return response.data;
  } catch (error) {
    return {
      ip: null,
    };
  }
};

export const addUserDeviceInfo = async (data: {
  user_agent: string;
  ip_address: string | null;
  timezone: string;
}) => {
  try {
    const response = await apiService({
      method: 'POST',
      url: `${baseURL}${apiRoutes.addUserDeviceInfo}`,
      data,
    });
    return response.data;
  } catch (error) {
    console.error('Error updating job as seen:', error);
    throw error;
  }
};

export const surveyForm = async (data: {
  survey_parent_option: string | null;
  survey_child_option: string | null;
  survey_other_option: string | null;
}) => {
  try {
    const response = await apiService({
      method: 'POST',
      url: `${baseURL}${apiRoutes.surveyForm}`,
      data,
    });
    return response.data;
  } catch (error) {
    console.error('Error updating job as seen:', error);
    throw error;
  }
};
